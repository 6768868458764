import { Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { MembershipComponent } from './membership/membership.component';
import { StyleGuideComponent } from './style-guide/style-guide.component';
import { TagComponent } from './tag/tag.component';

export const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'style-guide',
    component: StyleGuideComponent,
  },
  {
    path: 'tag',
    component: TagComponent,
  },
  {
    path: 'membership',
    component: MembershipComponent,
  },
];
