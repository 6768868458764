import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { MembershipComponent } from './membership/membership.component';
import { StyleGuideComponent } from './style-guide/style-guide.component';
import { TagComponent } from './tag/tag.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    StyleGuideComponent,
    HomeComponent,
    TagComponent,
    MembershipComponent,
    RouterOutlet,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
})
export class AppComponent {
  title = 'qizy-know-web';
}
