<!-- start: #wrapper -->
<div id="wrapper">
  <!-- start: #header -->
  <header id="header" class="minimalist">
    <div class="menu-mobile">
      <svg class="icon ularge open"><use xlink:href="#menu-icon"></use></svg>
      <svg class="icon ularge close">
        <use xlink:href="#close-icon"></use>
      </svg>
    </div>

  
  </header>
  <!-- end: #header -->

  <main id="archives" class="main">
    <div class="grid-container grid-medium">
      <div class="tag-description bg-box">
        <div class="left grid-60 tablet-grid-60 np-mobile">
          <h1 class="title bordered no-margin">
            Design<svg class="decoration">
              <use xlink:href="#title-decoration"></use>
            </svg>
          </h1>
          <div class="total">
            9 <span class="dot small"></span> Articles in this Category
          </div>
          <div class="epcl-decoration-counter">9</div>
          <p>
            Unleash your creativity and explore innovative trends in the world
            of design through our &#x27;Design&#x27; category. Discover
            inspiring concepts, expert tips, and the artistry that shapes our
            visual environment.
          </p>
          <a
            href="#post-list"
            class="epcl-button gradient-button icon wave-button scrollto"
          >
            <svg><use xlink:href="#double-arrow-icon"></use></svg>
            Explore
          </a>
        </div>
        <div
          class="right grid-40 tablet-grid-40 hide-on-mobile np-tablet np-mobile"
        >
          <img
            class="fullwidth"
            src="../../content/images/size/w660h660/2023/10/Untitled--7--1.jpg"
            alt="Design"
          />
        </div>
        <div class="clear"></div>
        <svg
          class="bg-decoration"
          width="327"
          height="265"
          viewBox="0 0 327 265"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_872_366)">
            <path
              d="M327 114.469C291.048 114.469 272.813 100.796 255.183 87.5686C238.744 75.2314 223.208 63.5938 191.333 63.5938C159.458 63.5938 143.923 75.2473 127.477 87.5686C109.847 100.796 91.6183 114.469 55.66 114.469C19.7017 114.469 1.47981 100.796 -16.1502 87.5686C-32.5898 75.2314 -48.125 63.5938 -80 63.5938V0C-44.0483 0 -25.8198 13.6727 -8.18977 26.9002C8.24984 39.2373 23.785 50.875 55.66 50.875C87.535 50.875 103.07 39.2214 119.51 26.9002C137.146 13.6727 155.375 0 191.333 0C227.292 0 245.52 13.6727 263.15 26.9002C279.59 39.2373 295.125 50.875 327.007 50.875V114.469H327Z"
            />
          </g>
          <g clip-path="url(#clip1_872_366)">
            <path
              d="M327 228.938C291.048 228.938 272.813 215.265 255.183 202.037C238.744 189.7 223.208 178.062 191.333 178.062C159.458 178.062 143.923 189.716 127.477 202.037C109.847 215.265 91.6183 228.938 55.66 228.938C19.7017 228.938 1.47981 215.265 -16.1502 202.037C-32.5898 189.7 -48.125 178.062 -80 178.062V114.469C-44.0483 114.469 -25.8198 128.141 -8.18977 141.369C8.24984 153.706 23.785 165.344 55.66 165.344C87.535 165.344 103.07 153.69 119.51 141.369C137.146 128.141 155.375 114.469 191.333 114.469C227.292 114.469 245.52 128.141 263.15 141.369C279.59 153.706 295.125 165.344 327.007 165.344V228.938H327Z"
            />
          </g>
          <g clip-path="url(#clip2_872_366)">
            <path
              d="M327 343.406C291.048 343.406 272.813 329.734 255.183 316.506C238.744 304.169 223.208 292.531 191.333 292.531C159.458 292.531 143.923 304.185 127.477 316.506C109.847 329.734 91.6183 343.406 55.66 343.406C19.7017 343.406 1.47981 329.734 -16.1502 316.506C-32.5898 304.169 -48.125 292.531 -80 292.531V228.938C-44.0483 228.938 -25.8198 242.61 -8.18977 255.838C8.24984 268.175 23.785 279.812 55.66 279.812C87.535 279.812 103.07 268.159 119.51 255.838C137.146 242.61 155.375 228.938 191.333 228.938C227.292 228.938 245.52 242.61 263.15 255.838C279.59 268.175 295.125 279.812 327.007 279.812V343.406H327Z"
            />
          </g>
        </svg>
      </div>
    </div>

    <div id="post-list" class="grid-container">
      <!-- start: .content-wrapper -->
      <div class="content-wrapper content">
        <div class="center left-content grid-70">
          <!-- start: .articles -->
          <div class="articles classic">
            <article class="default classic-large primary-cat-4 ctag-nature">
              <div class="post-format-image epcl-flex">
                <div class="featured-image">
                  <a
                    href="../../modern-and-colorful-style-of-caricatures-created-by-ai/index.html"
                    class="thumb epcl-loader"
                  >
                    <img
                      class="fullimage cover"
                      loading="eager"
                      src="../../content/images/size/w660h660/2024/01/Untitled-3.webp"
                      alt="Image of: Modern and colorful style of caricatures created by AI"
                    />
                    <span class="screen-reader-text"
                      >Modern and colorful style of caricatures created by
                      AI</span
                    >
                  </a>
                  <div class="meta absolute hide-on-mobile hide-on-tablet">
                    <a
                      href="../../modern-and-colorful-style-of-caricatures-created-by-ai/index.html#comments"
                      class="comments icon mobile tooltip"
                      data-title="Go to comments"
                      ><svg class="icon large">
                        <use xlink:href="#comments-icon"></use>
                      </svg>
                      <span class="comment-count"
                        ><script
                          data-ghost-comment-count="655d48231fc647756cef186e"
                          data-ghost-comment-count-empty="0 Comments"
                          data-ghost-comment-count-singular="Comment"
                          data-ghost-comment-count-plural="% Comments"
                          data-ghost-comment-count-tag="script"
                          data-ghost-comment-count-class-name=""
                          data-ghost-comment-count-autowrap="false"
                        ></script></span
                    ></a>
                  </div>
                </div>

                <div class="info">
                  <header>
                    <h1 class="main-title title underline-effect">
                      <a
                        href="../../modern-and-colorful-style-of-caricatures-created-by-ai/index.html"
                        >Modern and colorful style of caricatures created by
                        AI</a
                      >
                    </h1>
                    <div class="meta">
                      <a
                        href="../../author/jonathan-doe/index.html"
                        class="author"
                      >
                        <span
                          class="author-image cover lazy epcl-loader"
                          data-src="/wavy/content/images/size/w120h120/2023/11/Untitled--12--1.jpeg"
                        ></span>
                        <span class="author-name"
                          ><span>By</span> Adriana Martins</span
                        >
                      </a>
                      <time class="meta-info" datetime="2023-10-21">
                        <span class="dot"></span>October 21, 2023
                      </time>
                      <div class="clear"></div>
                    </div>
                  </header>
                  <div class="post-excerpt">
                    <p>
                      AI-powered tools and algorithms have redefined caricature
                      creation in novel....
                    </p>
                    <div class="clear"></div>
                  </div>
                </div>
              </div>

              <div class="clear"></div>
            </article>
            <article class="default classic-large primary-cat-4 ctag-news">
              <div class="post-format-image epcl-flex">
                <div class="featured-image">
                  <a
                    href="../../more-effective-work-schedules-in-remote-work/index.html"
                    class="thumb epcl-loader"
                  >
                    <img
                      class="fullimage cover"
                      loading="lazy"
                      decoding="async"
                      fetchpriority="low"
                      src="../../content/images/size/w660h660/2023/10/Untitled--5--2.jpg"
                      alt="Image of: More effective schedules in remote work"
                    />
                    <span class="screen-reader-text"
                      >More effective schedules in remote work</span
                    >
                  </a>
                  <div class="meta absolute hide-on-mobile hide-on-tablet">
                    <a
                      href="../../more-effective-work-schedules-in-remote-work/index.html#comments"
                      class="comments icon mobile tooltip"
                      data-title="Go to comments"
                      ><svg class="icon large">
                        <use xlink:href="#comments-icon"></use>
                      </svg>
                      <span class="comment-count"
                        ><script
                          data-ghost-comment-count="655d48231fc647756cef185e"
                          data-ghost-comment-count-empty="0 Comments"
                          data-ghost-comment-count-singular="Comment"
                          data-ghost-comment-count-plural="% Comments"
                          data-ghost-comment-count-tag="script"
                          data-ghost-comment-count-class-name=""
                          data-ghost-comment-count-autowrap="false"
                        ></script></span
                    ></a>
                  </div>

                  <a
                    href="../../more-effective-work-schedules-in-remote-work/index.html"
                    class="access-icon visibility-members meta-info tooltip"
                    data-title="Members Article"
                    aria-label="Members Article"
                  >
                    <svg><use xlink:href="#locked"></use></svg>
                  </a>
                </div>

                <div class="info">
                  <header>
                    <h1 class="main-title title underline-effect">
                      <a
                        href="../../more-effective-work-schedules-in-remote-work/index.html"
                        >More effective schedules in remote work</a
                      >
                    </h1>
                    <div class="meta">
                      <a
                        href="../../author/james-brawson/index.html"
                        class="author"
                      >
                        <span
                          class="author-image cover lazy epcl-loader"
                          data-src="/wavy/content/images/size/w120h120/2023/10/Untitled--14-.jpeg"
                        ></span>
                        <span class="author-name"
                          ><span>By</span> James Brawson</span
                        >
                      </a>
                      <time class="meta-info" datetime="2023-10-21">
                        <span class="dot"></span>October 21, 2023
                      </time>
                      <div class="clear"></div>
                    </div>
                  </header>
                  <div class="post-excerpt">
                    <p>
                      Discover how to optimize your tasks while working remotely
                      and enjoy your day....
                    </p>
                    <div class="clear"></div>
                  </div>
                  <footer class="bottom">
                    <div class="meta bottom epcl-flex">
                      <a
                        href="../../more-effective-work-schedules-in-remote-work/index.html"
                        class="continue-reading epcl-button gradient-button wave-button"
                        >Read More
                        <span class="screen-reader-text"
                          >More effective schedules in remote work</span
                        ></a
                      >
                      <div class="min-read">
                        <svg><use xlink:href="#reading-icon"></use></svg> 1 min
                        read
                      </div>
                    </div>
                  </footer>
                </div>
              </div>

              <div class="clear"></div>
            </article>
            <article class="default classic-large primary-cat-4 ctag-design">
              <div class="post-format-image epcl-flex">
                <div class="featured-image">
                  <a
                    href="../../from-pixels-to-masterpieces-ai-in-the-arts-world/index.html"
                    class="thumb epcl-loader"
                  >
                    <img
                      class="fullimage cover"
                      loading="lazy"
                      decoding="async"
                      fetchpriority="low"
                      src="../../content/images/size/w660h660/2023/10/Firefly-flor--render-3d-sobre-fondo-plano--colores-pasteles-suaves--fondo-de-1-o-2-colores-morados-y.jpg"
                      alt="Image of: From pixels to masterpieces: AI in the arts world"
                    />
                    <span class="screen-reader-text"
                      >From pixels to masterpieces: AI in the arts world</span
                    >
                  </a>
                  <div class="meta absolute hide-on-mobile hide-on-tablet">
                    <a
                      href="../../from-pixels-to-masterpieces-ai-in-the-arts-world/index.html#comments"
                      class="comments icon mobile tooltip"
                      data-title="Go to comments"
                      ><svg class="icon large">
                        <use xlink:href="#comments-icon"></use>
                      </svg>
                      <span class="comment-count"
                        ><script
                          data-ghost-comment-count="655d48231fc647756cef186a"
                          data-ghost-comment-count-empty="0 Comments"
                          data-ghost-comment-count-singular="Comment"
                          data-ghost-comment-count-plural="% Comments"
                          data-ghost-comment-count-tag="script"
                          data-ghost-comment-count-class-name=""
                          data-ghost-comment-count-autowrap="false"
                        ></script></span
                    ></a>
                  </div>

                  <a
                    href="../../from-pixels-to-masterpieces-ai-in-the-arts-world/index.html"
                    class="access-icon visibility-paid meta-info tooltip"
                    data-title="Premium Article"
                    aria-label="Premium Article"
                  >
                    <svg><use xlink:href="#vip"></use></svg>
                  </a>
                </div>

                <div class="info">
                  <header>
                    <h1 class="main-title title underline-effect">
                      <a
                        href="../../from-pixels-to-masterpieces-ai-in-the-arts-world/index.html"
                        >From pixels to masterpieces: AI in the arts world</a
                      >
                    </h1>
                    <div class="meta">
                      <a
                        href="../../author/jonathan-doe/index.html"
                        class="author"
                      >
                        <span
                          class="author-image cover lazy epcl-loader"
                          data-src="/wavy/content/images/size/w120h120/2023/11/Untitled--12--1.jpeg"
                        ></span>
                        <span class="author-name"
                          ><span>By</span> Adriana Martins</span
                        >
                      </a>
                      <time class="meta-info" datetime="2023-10-21">
                        <span class="dot"></span>October 21, 2023
                      </time>
                      <div class="clear"></div>
                    </div>
                  </header>
                  <div class="post-excerpt">
                    <p>
                      Digital art has reached new limits in terms of creativity
                      and quality, discover why...
                    </p>
                    <div class="clear"></div>
                  </div>
                  <footer class="bottom">
                    <div class="meta bottom epcl-flex">
                      <a
                        href="../../from-pixels-to-masterpieces-ai-in-the-arts-world/index.html"
                        class="continue-reading epcl-button gradient-button wave-button"
                        >Read More
                        <span class="screen-reader-text"
                          >From pixels to masterpieces: AI in the arts
                          world</span
                        ></a
                      >
                      <div class="min-read">
                        <svg><use xlink:href="#reading-icon"></use></svg> 2 min
                        read
                      </div>
                    </div>
                  </footer>
                </div>
              </div>

              <div class="clear"></div>
            </article>
            <div class="epcl-subscribe epcl-gradient">
              <div
                class="grid-container grid-usmall grid-parent np-mobile textcenter"
              >
                <form class="subscribe-form" data-members-form="subscribe">
                  <h2 class="title large white">Subscribe to our Newsletter</h2>
                  <p class="description">
                    Subscribe to our email newsletter and unlock access to
                    <b>members-only</b> content and <b>exclusive updates.</b>
                  </p>
                  <div class="form-group">
                    <input
                      type="email"
                      name="email"
                      class="inputbox large"
                      required
                      placeholder="Enter your email address"
                      data-members-email
                    />
                    <button
                      class="epcl-button submit absolute wave-button"
                      type="submit"
                    >
                      Get Started<span class="loader"></span>
                    </button>
                  </div>
                  <p class="error-detail" data-members-error></p>
                  <p class="success-message">
                    Subscription was sent successfully, check your email
                    <i class="fa fa-envelope-o"></i>
                  </p>
                </form>
              </div>
              <svg
                class="epcl-waves"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                viewBox="0 24 150 28"
                preserveAspectRatio="none"
                shape-rendering="auto"
              >
                <defs>
                  <path
                    id="gentle-wave"
                    d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
                  />
                </defs>
                <g class="epcl-parallax">
                  <use
                    xlink:href="#gentle-wave"
                    x="48"
                    y="2"
                    fill="rgba(252, 255, 255,0.1)"
                  />
                  <use
                    xlink:href="#gentle-wave"
                    x="48"
                    y="4"
                    fill="rgba(252, 255, 255,0.15)"
                  />
                  <use
                    xlink:href="#gentle-wave"
                    x="48"
                    y="7"
                    fill="rgba(252, 255, 255,0.1)"
                  />
                </g>
              </svg>
            </div>
            <article class="default classic-large primary-cat-4 ctag-design">
              <div class="post-format-image epcl-flex">
                <div class="featured-image">
                  <a
                    href="../../artificial-intelligence-in-the-generative-image-process/index.html"
                    class="thumb epcl-loader"
                  >
                    <img
                      class="fullimage cover"
                      loading="lazy"
                      decoding="async"
                      fetchpriority="low"
                      src="../../content/images/size/w660h660/2023/10/Firefly-joystick-blanco--render-3d-sobre-fondo-plano--colores-pasteles-suaves--fondo-de-1-o-2-colore--2-.jpg"
                      alt="Image of: Artificial Intelligence in the generative image process"
                    />
                    <span class="screen-reader-text"
                      >Artificial Intelligence in the generative image
                      process</span
                    >
                  </a>
                  <div class="meta absolute hide-on-mobile hide-on-tablet">
                    <a
                      href="../../artificial-intelligence-in-the-generative-image-process/index.html#comments"
                      class="comments icon mobile tooltip"
                      data-title="Go to comments"
                      ><svg class="icon large">
                        <use xlink:href="#comments-icon"></use>
                      </svg>
                      <span class="comment-count"
                        ><script
                          data-ghost-comment-count="655d48231fc647756cef1867"
                          data-ghost-comment-count-empty="0 Comments"
                          data-ghost-comment-count-singular="Comment"
                          data-ghost-comment-count-plural="% Comments"
                          data-ghost-comment-count-tag="script"
                          data-ghost-comment-count-class-name=""
                          data-ghost-comment-count-autowrap="false"
                        ></script></span
                    ></a>
                  </div>
                </div>

                <div class="info">
                  <header>
                    <h1 class="main-title title underline-effect">
                      <a
                        href="../../artificial-intelligence-in-the-generative-image-process/index.html"
                        >Artificial Intelligence in the generative image
                        process</a
                      >
                    </h1>
                    <div class="meta">
                      <a
                        href="../../author/jonathan-doe/index.html"
                        class="author"
                      >
                        <span
                          class="author-image cover lazy epcl-loader"
                          data-src="/wavy/content/images/size/w120h120/2023/11/Untitled--12--1.jpeg"
                        ></span>
                        <span class="author-name"
                          ><span>By</span> Adriana Martins</span
                        >
                      </a>
                      <time class="meta-info" datetime="2023-10-20">
                        <span class="dot"></span>October 20, 2023
                      </time>
                      <div class="clear"></div>
                    </div>
                  </header>
                  <div class="post-excerpt">
                    <p>
                      AI has rapidly evolved to play a significant role in the
                      generative image process...
                    </p>
                    <div class="clear"></div>
                  </div>
                  <footer class="bottom">
                    <div class="meta bottom epcl-flex">
                      <a
                        href="../../artificial-intelligence-in-the-generative-image-process/index.html"
                        class="continue-reading epcl-button gradient-button wave-button"
                        >Read More
                        <span class="screen-reader-text"
                          >Artificial Intelligence in the generative image
                          process</span
                        ></a
                      >
                      <div class="min-read">
                        <svg><use xlink:href="#reading-icon"></use></svg> 2 min
                        read
                      </div>
                    </div>
                  </footer>
                </div>
              </div>

              <div class="clear"></div>
            </article>
            <article class="default classic-large primary-cat-4 ctag-design">
              <div class="post-format-image epcl-flex">
                <div class="featured-image">
                  <a
                    href="../../restoring-old-designs-with-the-help-of-technology/index.html"
                    class="thumb epcl-loader"
                  >
                    <img
                      class="fullimage cover"
                      loading="lazy"
                      decoding="async"
                      fetchpriority="low"
                      src="../../content/images/size/w660h660/2023/10/Firefly-tierno-autom-vil--render-3d-sobre-fondo-plano--colores-pasteles-suaves--fondo-de-1-o-2-color.jpg"
                      alt="Image of: Restoring old designs with the help of technology"
                    />
                    <span class="screen-reader-text"
                      >Restoring old designs with the help of technology</span
                    >
                  </a>
                  <div class="meta absolute hide-on-mobile hide-on-tablet">
                    <a
                      href="../../restoring-old-designs-with-the-help-of-technology/index.html#comments"
                      class="comments icon mobile tooltip"
                      data-title="Go to comments"
                      ><svg class="icon large">
                        <use xlink:href="#comments-icon"></use>
                      </svg>
                      <span class="comment-count"
                        ><script
                          data-ghost-comment-count="655d48231fc647756cef186d"
                          data-ghost-comment-count-empty="0 Comments"
                          data-ghost-comment-count-singular="Comment"
                          data-ghost-comment-count-plural="% Comments"
                          data-ghost-comment-count-tag="script"
                          data-ghost-comment-count-class-name=""
                          data-ghost-comment-count-autowrap="false"
                        ></script></span
                    ></a>
                  </div>
                </div>

                <div class="info">
                  <header>
                    <h1 class="main-title title underline-effect">
                      <a
                        href="../../restoring-old-designs-with-the-help-of-technology/index.html"
                        >Restoring old designs with the help of technology</a
                      >
                    </h1>
                    <div class="meta">
                      <a
                        href="../../author/jonathan-doe/index.html"
                        class="author"
                      >
                        <span
                          class="author-image cover lazy epcl-loader"
                          data-src="/wavy/content/images/size/w120h120/2023/11/Untitled--12--1.jpeg"
                        ></span>
                        <span class="author-name"
                          ><span>By</span> Adriana Martins</span
                        >
                      </a>
                      <time class="meta-info" datetime="2023-10-20">
                        <span class="dot"></span>October 20, 2023
                      </time>
                      <div class="clear"></div>
                    </div>
                  </header>
                  <div class="post-excerpt">
                    <p>
                      Technology can be our great ally when it comes to easily
                      process designs from past eras...
                    </p>
                    <div class="clear"></div>
                  </div>
                  <footer class="bottom">
                    <div class="meta bottom epcl-flex">
                      <a
                        href="../../restoring-old-designs-with-the-help-of-technology/index.html"
                        class="continue-reading epcl-button gradient-button wave-button"
                        >Read More
                        <span class="screen-reader-text"
                          >Restoring old designs with the help of
                          technology</span
                        ></a
                      >
                      <div class="min-read">
                        <svg><use xlink:href="#reading-icon"></use></svg> 2 min
                        read
                      </div>
                    </div>
                  </footer>
                </div>
              </div>

              <div class="clear"></div>
            </article>
            <article class="default classic-large primary-cat-4 ctag-design">
              <div class="post-format-image epcl-flex">
                <div class="featured-image">
                  <a
                    href="../../nosidebar/index.html"
                    class="thumb epcl-loader"
                  >
                    <img
                      class="fullimage cover"
                      loading="lazy"
                      decoding="async"
                      fetchpriority="low"
                      src="../../content/images/size/w660h660/2023/10/Firefly-tierno-edificio--render-3d-sobre-fondo-plano--colores-pasteles-suaves--fondo-de-1-o-2-colore--3-.jpg"
                      alt="Image of: The way forward for AI in art constructions"
                    />
                    <span class="screen-reader-text"
                      >The way forward for AI in art constructions</span
                    >
                  </a>
                  <div class="meta absolute hide-on-mobile hide-on-tablet">
                    <a
                      href="../../nosidebar/index.html#comments"
                      class="comments icon mobile tooltip"
                      data-title="Go to comments"
                      ><svg class="icon large">
                        <use xlink:href="#comments-icon"></use>
                      </svg>
                      <span class="comment-count"
                        ><script
                          data-ghost-comment-count="655d48231fc647756cef1869"
                          data-ghost-comment-count-empty="0 Comments"
                          data-ghost-comment-count-singular="Comment"
                          data-ghost-comment-count-plural="% Comments"
                          data-ghost-comment-count-tag="script"
                          data-ghost-comment-count-class-name=""
                          data-ghost-comment-count-autowrap="false"
                        ></script></span
                    ></a>
                  </div>
                </div>

                <div class="info">
                  <header>
                    <h1 class="main-title title underline-effect">
                      <a href="../../nosidebar/index.html"
                        >The way forward for AI in art constructions</a
                      >
                    </h1>
                    <div class="meta">
                      <a
                        href="../../author/jonathan-doe/index.html"
                        class="author"
                      >
                        <span
                          class="author-image cover lazy epcl-loader"
                          data-src="/wavy/content/images/size/w120h120/2023/11/Untitled--12--1.jpeg"
                        ></span>
                        <span class="author-name"
                          ><span>By</span> Adriana Martins</span
                        >
                      </a>
                      <time class="meta-info" datetime="2023-01-24">
                        <span class="dot"></span>January 24, 2023
                      </time>
                      <div class="clear"></div>
                    </div>
                  </header>
                  <div class="post-excerpt">
                    <p>
                      Artificial intelligence has introduced a fresh perspective
                      to artistic creation....
                    </p>
                    <div class="clear"></div>
                  </div>
                  <footer class="bottom">
                    <div class="meta bottom epcl-flex">
                      <a
                        href="../../nosidebar/index.html"
                        class="continue-reading epcl-button gradient-button wave-button"
                        >Read More
                        <span class="screen-reader-text"
                          >The way forward for AI in art constructions</span
                        ></a
                      >
                      <div class="min-read">
                        <svg><use xlink:href="#reading-icon"></use></svg> 2 min
                        read
                      </div>
                    </div>
                  </footer>
                </div>
              </div>

              <div class="clear"></div>
            </article>
          </div>
          <!-- end: .articles -->

          <nav class="epcl-pagination section">
            <div class="nav">
              <span class="page-number">Page 1 of 2</span>
              <a
                class="next epcl-button gradient-button wave-button medium"
                href="page/2/index.html"
                >Next Page</a
              >
            </div>
          </nav>
        </div>

        <!-- start: #sidebar -->
        <aside
          id="sidebar"
          class="grid-30 tablet-grid-30 hide-on-tablet hide-on-mobile"
        >
          <!-- start: .widget-text-->
          <div
            class="widget widget_epcl_about grid-25 tablet-grid-50 mobile-grid-100"
          >
            <div class="widget-content">
              <h2 class="widget-title title medium bordered">
                <span>About Me</span
                ><svg class="decoration">
                  <use xlink:href="#title-decoration"></use>
                </svg>
              </h2>
              <div class="epcl-flex">
                <div class="avatar">
                  <a href="../../author/jonathan-doe/index.html" class="thumb"
                    ><span
                      class="fullimage cover"
                      style="
                        background-image: url(../../content/images/size/w300h300/2023/11/Untitled--12--1.jpg);
                      "
                    ></span>
                    <span class="screen-reader-text">Adriana Martins</span></a
                  >
                </div>
                <div class="info">
                  <h3
                    class="title usmall author-name underline-effect no-margin"
                  >
                    <a href="../../author/jonathan-doe/index.html"
                      >Adriana Martins</a
                    >
                  </h3>
                  <p class="founder">Canada</p>
                </div>
              </div>
              <div class="bio">
                <p>
                  Hello! My name is Adriana Martins working from Chile. I create
                  some Ghost and Wordpress themes for differents markets, also,
                  i offer live support via our ticket system.
                </p>
              </div>
            </div>
          </div>
          <!-- end: .widget-text -->

          <!-- start: .widget-social -->
          <div
            class="widget widget_epcl_social grid-33 tablet-grid-50 mobile-grid-100"
          >
            <h3 class="widget-title title medium bordered">
              <span>Social Profiles</span
              ><svg class="decoration">
                <use xlink:href="#title-decoration"></use>
              </svg>
            </h3>
            <ul class="icons">
              <li>
                <a
                  href="https://www.facebook.com/ghost"
                  class="facebook translate-effect"
                  target="_blank"
                  rel="nofollow noopener"
                >
                  <span class="icon"
                    ><svg class="icon small">
                      <use xlink:href="#facebook-icon"></use>
                    </svg>
                    ></span
                  >
                  <span class="name">Facebook</span>
                </a>
              </li>
              <li>
                <a
                  href="https://twitter.com/tryghost"
                  class="twitter translate-effect"
                  target="_blank"
                  rel="nofollow noopener"
                >
                  <span class="icon"
                    ><svg class="icon small">
                      <use xlink:href="#twitter-icon"></use>
                    </svg>
                    ></span
                  >
                  <span class="name">Twitter</span>
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/estudiopatagon/"
                  class="instagram translate-effect"
                  target="_blank"
                >
                  <span class="icon"
                    ><svg class="icon">
                      <use xlink:href="#instagram-icon"></use>
                    </svg>
                    ></span
                  >
                  <span class="name">Instagram</span>
                </a>
              </li>
              <li>
                <a
                  href="https://wa.me/5492996155777"
                  class="whatsapp translate-effect"
                  target="_blank"
                >
                  <span class="icon"
                    ><svg class="icon">
                      <use xlink:href="#whatsapp-icon"></use>
                    </svg>
                    ></span
                  >
                  <span class="name">WhatsApp</span>
                </a>
              </li>
              <li>
                <a
                  href="https://discord.gg/estudiopatagon"
                  class="discord translate-effect"
                  target="_blank"
                >
                  <span class="icon"
                    ><svg class="icon">
                      <use xlink:href="#discord-icon"></use>
                    </svg>
                    ></span
                  >
                  <span class="name">Discord</span>
                </a>
              </li>
              <li>
                <a
                  href="../../rss/index.html"
                  class="rss translate-effect"
                  target="_blank"
                >
                  <span class="icon rss"><i class="fa fa-rss"></i></span>
                  <span class="name">RSS</span>
                </a>
              </li>
            </ul>
          </div>
          <!-- end: .widget-social-->
          <!-- start: featured-articles -->
          <div
            class="widget widget_epcl_posts_thumbs grid-25 tablet-grid-50 mobile-grid-100"
          >
            <h3 class="widget-title title medium bordered">
              <span>Featured Articles</span
              ><svg class="decoration">
                <use xlink:href="#title-decoration"></use>
              </svg>
            </h3>
            <article
              class="post tag-news tag-sports tag-hash-import-2023-11-22-00-15 tag-hash-jonathan-doe featured item ctag-news"
            >
              <div class="info">
                <a
                  href="../../some-sports-that-will-be-affected-by-big-data-advances/index.html"
                  class="thumb translate-effect epcl-loader"
                >
                  <span
                    class="fullimage cover lazy"
                    data-src="../../content/images/size/w300h300/2023/10/Firefly-tierno-bal-n-de-b-isbol--render-3d-sobre-fondo-plano--colores-pasteles-suaves--fondo-de-1-o-.jpg"
                  ></span>
                  <span class="screen-reader-text"
                    >Some sports that will be affected by big-data
                    advances</span
                  >
                </a>
                <div class="right">
                  <h4 class="title usmall underline-effect">
                    <a
                      href="../../some-sports-that-will-be-affected-by-big-data-advances/index.html"
                      >Some sports that will be affected by big-data advances</a
                    >
                  </h4>
                  <div class="meta small">
                    <a
                      href="../../author/jonathan-doe/index.html"
                      class="author"
                    >
                      <span class="dot small"></span>
                      <span class="author-name"
                        ><span>By</span> Adriana Martins</span
                      >
                    </a>
                  </div>
                </div>
              </div>
              <div class="clear"></div>
            </article>
            <article
              class="post tag-nature tag-hash-import-2023-11-22-00-15 featured item ctag-nature"
            >
              <div class="info">
                <a
                  href="../../animals-that-captivate-people-with-their-intelligence/index.html"
                  class="thumb translate-effect epcl-loader"
                >
                  <span
                    class="fullimage cover lazy"
                    data-src="../../content/images/size/w300h300/2023/10/Untitled--2--2.jpg"
                  ></span>
                  <span class="screen-reader-text"
                    >Animals that captivate people with their intelligence</span
                  >
                </a>
                <div class="right">
                  <h4 class="title usmall underline-effect">
                    <a
                      href="../../animals-that-captivate-people-with-their-intelligence/index.html"
                      >Animals that captivate people with their intelligence</a
                    >
                  </h4>
                  <div class="meta small">
                    <a
                      href="../../author/james-brawson/index.html"
                      class="author"
                    >
                      <span class="dot small"></span>
                      <span class="author-name"
                        ><span>By</span> James Brawson</span
                      >
                    </a>
                  </div>
                </div>
              </div>
              <div class="clear"></div>
            </article>
            <article
              class="post tag-design tag-technology tag-news tag-hash-import-2023-11-22-00-15 featured item ctag-design"
            >
              <div class="info">
                <a
                  href="../../fullwidth/index.html"
                  class="thumb translate-effect epcl-loader"
                >
                  <span
                    class="fullimage cover lazy"
                    data-src="../../content/images/size/w300h300/2023/10/Firefly-c-modo-sill-n-blanco--render-3d-sobre-fondo-plano--colores-pasteles-suaves--fondo-de-1-o-2-c--2-.jpg"
                  ></span>
                  <span class="screen-reader-text"
                    >Quickly plan interior design with the help of
                    renderings</span
                  >
                </a>
                <div class="right">
                  <h4 class="title usmall underline-effect">
                    <a href="../../fullwidth/index.html"
                      >Quickly plan interior design with the help of
                      renderings</a
                    >
                  </h4>
                  <div class="meta small">
                    <a
                      href="../../author/jonathan-doe-2/index.html"
                      class="author"
                    >
                      <span class="dot small"></span>
                      <span class="author-name"
                        ><span>By</span> Jonathan Doe</span
                      >
                    </a>
                  </div>
                </div>
              </div>
              <div class="clear"></div>
            </article>
          </div>
          <!-- end: featured-articles -->

          <!-- start: .widget-tag-carousel -->
          <div
            class="widget widget_epcl_tag_carousel grid-25 tablet-grid-50 mobile-grid-100"
          >
            <h3 class="widget-title title medium bordered">
              <span>Categories</span
              ><svg class="decoration">
                <use xlink:href="#title-decoration"></use>
              </svg>
            </h3>
            <div class="epcl-carousel slick-slider bottom-arrows" data-show="1">
              <div class="slick-slide item">
                <a href="index.html" class="thumb">
                  <img
                    class="cover lazy"
                    data-src="../../content/images/size/w630h400/2023/10/Untitled--7--1.jpg"
                  />
                  <span class="name title small">Design</span>
                  <span class="count"
                    >9 <span class="dot small"></span> Articles</span
                  >
                </a>
                <div class="clear"></div>
              </div>
              <div class="slick-slide item">
                <a href="../news/index.html" class="thumb">
                  <img
                    class="cover lazy"
                    data-src="../../content/images/size/w630h400/2024/01/Firefly-3D-render-of-a-fluffy-building--Egypt--minimal--inspired-by-Ricardo-Bofill--retrofuturism--p.jpg"
                  />
                  <span class="name title small">News</span>
                  <span class="count"
                    >5 <span class="dot small"></span> Articles</span
                  >
                </a>
                <div class="clear"></div>
              </div>
              <div class="slick-slide item">
                <a href="../nature/index.html" class="thumb">
                  <img
                    class="cover lazy"
                    data-src="../../content/images/size/w630h400/2023/10/Untitled--10-.jpg"
                  />
                  <span class="name title small">Nature</span>
                  <span class="count"
                    >4 <span class="dot small"></span> Articles</span
                  >
                </a>
                <div class="clear"></div>
              </div>
              <div class="slick-slide item">
                <a href="../technology/index.html" class="thumb">
                  <img
                    class="cover lazy"
                    data-src="../../content/images/size/w630h400/2023/10/Untitled--8-.jpg"
                  />
                  <span class="name title small">Technology</span>
                  <span class="count"
                    >3 <span class="dot small"></span> Articles</span
                  >
                </a>
                <div class="clear"></div>
              </div>
              <div class="slick-slide item">
                <a href="../health/index.html" class="thumb">
                  <img
                    class="cover lazy"
                    data-src="../../content/images/size/w630h400/2023/10/Untitled--11-.jpg"
                  />
                  <span class="name title small">Health</span>
                  <span class="count"
                    >1 <span class="dot small"></span> Article</span
                  >
                </a>
                <div class="clear"></div>
              </div>
              <div class="slick-slide item">
                <a href="../buildings/index.html" class="thumb">
                  <img
                    class="cover lazy"
                    data-src="../../content/images/size/w630h400/2023/10/Firefly-edificio-blanco--render-3d-sobre-fondo-plano--colores-pasteles-suaves--fondo-de-1-color-azul.jpg"
                  />
                  <span class="name title small">Buildings</span>
                  <span class="count"
                    >1 <span class="dot small"></span> Article</span
                  >
                </a>
                <div class="clear"></div>
              </div>
              <div class="slick-slide item">
                <a href="../sports/index.html" class="thumb">
                  <img
                    class="cover lazy"
                    data-src="../../content/images/size/w630h400/2023/10/Firefly-bal-n-de-baloncesto-naranjo--render-3d-sobre-fondo-plano--colores-pasteles-suaves--fondo-de-.jpg"
                  />
                  <span class="name title small">Sports</span>
                  <span class="count"
                    >1 <span class="dot small"></span> Article</span
                  >
                </a>
                <div class="clear"></div>
              </div>
            </div>
          </div>
          <!-- end: .widget-tag-carousel -->
        </aside>
        <!-- end: #sidebar -->
      </div>
      <!-- end: .content-wrapper -->
    </div>

    <div class="epcl-waves-page">
      <svg class="wave wave-1" xmlns="http://www.w3.org/2000/svg">
        <rect width="100%" height="400" fill="url(#bg-wave)" />
      </svg>
      <svg class="wave wave-2" xmlns="http://www.w3.org/2000/svg">
        <rect width="100%" height="400" fill="url(#bg-wave2)" />
      </svg>
      <svg class="wave wave-3" xmlns="http://www.w3.org/2000/svg">
        <rect width="100%" height="400" fill="url(#bg-wave)" />
      </svg>
    </div>

    <svg
      id="epcl-wave-pattern"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      style="position: absolute; z-index: -10"
    >
      <defs>
        <pattern
          class="epcl-pattern epcl-wave-color"
          id="bg-wave"
          patternUnits="userSpaceOnUse"
          width="1000"
          height="400"
        >
          <path
            d="M1484.98 0.0853853C1402.51 2.28495 1322.66 46.58 1242.81 90.875C1160.9 136.313 1078.98 181.75 994.246 181.75C909.509 181.75 827.597 136.313 745.685 90.875C663.772 45.4375 581.86 0 497.123 0C412.387 0 330.474 45.4375 248.562 90.875C166.65 136.313 84.7374 181.75 0.000428317 181.75L0 399.765C82.4694 397.566 162.322 353.271 242.175 308.976C324.087 263.538 405.999 218.101 490.736 218.101C575.473 218.101 657.385 263.538 739.298 308.976C821.21 354.413 903.122 399.851 987.859 399.851C1072.6 399.851 1154.51 354.413 1236.42 308.976C1318.33 263.538 1400.25 218.101 1484.98 218.101L1484.98 0.0853853Z"
            fill-opacity="0.05"
          />
        </pattern>
        <pattern
          class="epcl-pattern epcl-wave-color2"
          id="bg-wave2"
          patternUnits="userSpaceOnUse"
          width="1000"
          height="400"
        >
          <path
            d="M1484.98 0.0853853C1402.51 2.28495 1322.66 46.58 1242.81 90.875C1160.9 136.313 1078.98 181.75 994.246 181.75C909.509 181.75 827.597 136.313 745.685 90.875C663.772 45.4375 581.86 0 497.123 0C412.387 0 330.474 45.4375 248.562 90.875C166.65 136.313 84.7374 181.75 0.000428317 181.75L0 399.765C82.4694 397.566 162.322 353.271 242.175 308.976C324.087 263.538 405.999 218.101 490.736 218.101C575.473 218.101 657.385 263.538 739.298 308.976C821.21 354.413 903.122 399.851 987.859 399.851C1072.6 399.851 1154.51 354.413 1236.42 308.976C1318.33 263.538 1400.25 218.101 1484.98 218.101L1484.98 0.0853853Z"
            fill-opacity="0.03"
          />
        </pattern>
      </defs>
    </svg>
  </main>
</div>
<!-- end: #wrapper -->

<div class="epcl-demo-tool hide-on-mobile hide-on-tablet hide-on-desktop-sm">
  <div class="tool" title="Demo options">
    <svg
      class="icon ularge"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" stroke="currentColor" stroke-width="2">
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M14 21h-4l-.551-2.48a6.991 6.991 0 0 1-1.819-1.05l-2.424.763l-2-3.464l1.872-1.718a7.055 7.055 0 0 1 0-2.1L3.206 9.232l2-3.464l2.424.763A6.992 6.992 0 0 1 9.45 5.48L10 3h4l.551 2.48a6.992 6.992 0 0 1 1.819 1.05l2.424-.763l2 3.464l-1.872 1.718a7.05 7.05 0 0 1 0 2.1l1.872 1.718l-2 3.464l-2.424-.763a6.99 6.99 0 0 1-1.819 1.052L14 21z"
        />
        <circle cx="12" cy="12" r="3" />
      </g>
    </svg>
  </div>
  <h4 class="title usmall">Change Styling:</h4>
  <a href="javascript:void(0)" class="active" data-class="disable-decorations"
    ><span></span>Waves Background</a
  >
  <label
    ><input
      type="color"
      value="#FC6668"
      data-class=".epcl-wave-color, .epcl-wave-color2"
      data-attr="fill"
    />
    Wave Color</label
  >
  <label
    ><input
      type="color"
      value="#FC6668"
      data-target="--epcl-gradient-start-color"
    /><input
      type="color"
      value="#e10489"
      data-target="--epcl-gradient-end-color"
    />
    Gradient</label
  >
  <label
    ><input type="color" value="#f43676" data-target="--main-color" /> Links
    Color</label
  >
  <label
    ><input
      type="color"
      value="#FFFFFF"
      data-class="body"
      data-attr="background"
    />
    Background Color</label
  >
  <label
    ><input
      type="color"
      value="#ffe7d2"
      data-target="--epcl-boxes-background-color"
    />
    Sidebar Color</label
  >
  <p style="font-size: 12px; padding-top: 5px">
    <b>Note:</b> All these options are included in the Ghost Admin.
  </p>
</div>
<a
  href="https://1.envato.market/ghost-wavy-purchase"
  target="_blank"
  class="epcl-purchase hide-on-mobile"
  >Purchase - <b>$69</b></a
>
