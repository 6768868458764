<!-- start: #wrapper -->
<div id="wrapper">
  <!-- start: #header -->

  <!-- end: #header -->

  <!-- start: #page -->
  <main id="page" class="main grid-container">
    <!-- start: .center -->
    <div id="single" class="membership-page center">
      <!-- start: .intro-text -->
      <div
        class="intro-text text textcenter section np-bottom grid-container grid-medium"
      >
        <h1 class="title ularge">Join us 🎉 and become a subscriber</h1>
        <p>
          Rich right us federal alone degree issue professor. Form crime tough
          effect least store day. Deep range they modern.
        </p>
      </div>
      <!-- end: .intro-text -->
      <div class="content">
        <!-- start: .left-content -->
        <div class="left-content">
          <article
            class="post tag-hash-import-2023-10-20-02-11 tag-hash-import-2023-11-22-00-15 tag-hash-jonathan-doe no-image"
          >
            <section class="post-content">
              <div class="epcl-row">
                <div class="textcenter">
                  <div class="epcl-switch" data-active-price="monthly">
                    <button data-price="monthly">Monthly</button>
                    <button data-price="yearly">Yearly</button>
                  </div>
                </div>
                <div class="clear"></div>
                <div class="epcl-plans epcl-flex" data-active-price="monthly">
                  <!-- start: .free -->
                  <div
                    class="epcl-plan grid-33 tablet-grid-33 np-mobile"
                    data-type="free"
                  >
                    <div class="subscribe-plan bg-box bg-white">
                      <h3 class="title large bold">Free</h3>
                      <div class="epcl-price" data-price="free">$0</div>
                      <p class="description">
                        <strong>Access to all free articles</strong>
                      </p>
                      <ul>
                        <li>Access to limited Articles</li>
                        <li>Articles with Advertising</li>
                        <li>No card required</li>
                      </ul>
                      <a
                        href="../subscribe/index.html"
                        class="epcl-button medium gradient-button wave-button"
                        >Sign Up for Free</a
                      >
                    </div>
                  </div>
                  <!-- end: .free -->
                  <!-- start: .free -->
                  <div
                    class="epcl-plan grid-33 tablet-grid-33 np-mobile"
                    data-type="paid"
                  >
                    <div class="subscribe-plan bg-box bg-white">
                      <h3 class="title large bold">Premium</h3>
                      <p class="free-trial">
                        <strong>14 Free trial days</strong>
                      </p>
                      <div class="epcl-price" data-price="monthly">
                        $5 <span class="interval">/Month</span>
                      </div>
                      <div class="epcl-price" data-price="yearly">
                        $50 <span class="interval">/Year</span>
                      </div>
                      <p class="description">
                        <strong>Full Access to Premium Content</strong>
                      </p>
                      <ul>
                        <li>Access to premium posts</li>
                        <li>Weekly newsletters</li>
                        <li>Simple, secure card payment</li>
                        <li>No Advertising</li>
                      </ul>
                      <a
                        href="javascript:void(0);"
                        class="epcl-button medium gradient-button wave-button epcl-pay-button"
                        data-portal="signup/655d47411fc647756cef15df/monthly"
                        data-price="monthly"
                        >Sign Up Now</a
                      >
                      <a
                        href="javascript:void(0);"
                        class="epcl-button medium gradient-button wave-button epcl-pay-button"
                        data-portal="signup/655d47411fc647756cef15df/yearly"
                        data-price="yearly"
                        >Sign Up Now</a
                      >
                    </div>
                  </div>
                  <!-- end: .free -->
                  <!-- start: .free -->
                  <div
                    class="epcl-plan grid-33 tablet-grid-33 np-mobile"
                    data-type="paid"
                  >
                    <div class="subscribe-plan bg-box bg-white">
                      <h3 class="title large bold">Golden+✨</h3>
                      <p class="free-trial">
                        <strong>7 Free trial days</strong>
                      </p>
                      <div class="epcl-price" data-price="monthly">
                        $7 <span class="interval">/Month</span>
                      </div>
                      <div class="epcl-price" data-price="yearly">
                        $70 <span class="interval">/Year</span>
                      </div>
                      <p class="description">
                        <strong
                          >Access to all premium content and special
                          offers</strong
                        >
                      </p>
                      <ul>
                        <li>Access to premium posts</li>
                        <li>Weekly newsletters</li>
                        <li>No Advertising</li>
                        <li>Special discounts</li>
                      </ul>
                      <a
                        href="javascript:void(0);"
                        class="epcl-button medium gradient-button wave-button epcl-pay-button"
                        data-portal="signup/655d79e0f664330a3bcee560/monthly"
                        data-price="monthly"
                        >Sign Up Now</a
                      >
                      <a
                        href="javascript:void(0);"
                        class="epcl-button medium gradient-button wave-button epcl-pay-button"
                        data-portal="signup/655d79e0f664330a3bcee560/yearly"
                        data-price="yearly"
                        >Sign Up Now</a
                      >
                    </div>
                  </div>
                  <!-- end: .free -->
                </div>

                <div class="clear"></div>
                <p class="underline-effect account textcenter">
                  Already have an account?
                  <a href="../signin/index.html" class="fw-bold">Sign In</a>
                </p>
              </div>

              <div class="clear"></div>
            </section>
          </article>
        </div>
        <!-- end: .content -->

        <div class="clear"></div>
      </div>
    </div>
    <!-- end: .center -->

    <div class="epcl-waves-page">
      <svg class="wave wave-1" xmlns="http://www.w3.org/2000/svg">
        <rect width="100%" height="400" fill="url(#bg-wave)" />
      </svg>
      <svg class="wave wave-2" xmlns="http://www.w3.org/2000/svg">
        <rect width="100%" height="400" fill="url(#bg-wave2)" />
      </svg>
      <svg class="wave wave-3" xmlns="http://www.w3.org/2000/svg">
        <rect width="100%" height="400" fill="url(#bg-wave)" />
      </svg>
    </div>

    <svg
      id="epcl-wave-pattern"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      style="position: absolute; z-index: -10"
    >
      <defs>
        <pattern
          class="epcl-pattern epcl-wave-color"
          id="bg-wave"
          patternUnits="userSpaceOnUse"
          width="1000"
          height="400"
        >
          <path
            d="M1484.98 0.0853853C1402.51 2.28495 1322.66 46.58 1242.81 90.875C1160.9 136.313 1078.98 181.75 994.246 181.75C909.509 181.75 827.597 136.313 745.685 90.875C663.772 45.4375 581.86 0 497.123 0C412.387 0 330.474 45.4375 248.562 90.875C166.65 136.313 84.7374 181.75 0.000428317 181.75L0 399.765C82.4694 397.566 162.322 353.271 242.175 308.976C324.087 263.538 405.999 218.101 490.736 218.101C575.473 218.101 657.385 263.538 739.298 308.976C821.21 354.413 903.122 399.851 987.859 399.851C1072.6 399.851 1154.51 354.413 1236.42 308.976C1318.33 263.538 1400.25 218.101 1484.98 218.101L1484.98 0.0853853Z"
            fill-opacity="0.05"
          />
        </pattern>
        <pattern
          class="epcl-pattern epcl-wave-color2"
          id="bg-wave2"
          patternUnits="userSpaceOnUse"
          width="1000"
          height="400"
        >
          <path
            d="M1484.98 0.0853853C1402.51 2.28495 1322.66 46.58 1242.81 90.875C1160.9 136.313 1078.98 181.75 994.246 181.75C909.509 181.75 827.597 136.313 745.685 90.875C663.772 45.4375 581.86 0 497.123 0C412.387 0 330.474 45.4375 248.562 90.875C166.65 136.313 84.7374 181.75 0.000428317 181.75L0 399.765C82.4694 397.566 162.322 353.271 242.175 308.976C324.087 263.538 405.999 218.101 490.736 218.101C575.473 218.101 657.385 263.538 739.298 308.976C821.21 354.413 903.122 399.851 987.859 399.851C1072.6 399.851 1154.51 354.413 1236.42 308.976C1318.33 263.538 1400.25 218.101 1484.98 218.101L1484.98 0.0853853Z"
            fill-opacity="0.03"
          />
        </pattern>
      </defs>
    </svg>
  </main>
  <!-- end: #page -->

  <!-- start: #footer -->

  <!-- end: #footer -->
</div>
<!-- end: #wrapper -->
